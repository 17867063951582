import AppPage from "../../common/AppPage";
import React, {useEffect, useState} from "react";
import {Button, Grid} from "@mui/material";
import {BaseCard} from "../../components/cards";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import OikosCard from "./oikos-card";
import {useTranslation} from "react-i18next";
import ROUTES from "../../routes";
import {useNavigate} from "react-router-dom";
import ValidationListItem from "../validations_list/validation-list-item";
import ScrollButtons from "../../components/scrollbar/scroll-buttons";
import {useOikosDashboard} from "../../services/dashboard/oikos-dashboard";
import {useResponsive} from "../../hooks/use-responsive";
import {useValidationReqDashboard} from "../../services/validationRequest";
import {useGoalsHistory} from "../../services";
import {DashboardChart} from "./dashboard-chart";
import {Mixpanel} from "../../metrics/mixpanel";

import toast from "react-hot-toast";
import BenefitsCard from "../../components/cards/benefits-card";
import {DashboardTour} from "../../components/tour";

function handleError(error, t, toast, navigate) {
    if (error.response.status === 404) {
        navigate(ROUTES.onboarding.profile);
    } else {
        toast.error(t("feedback.error.general_api_error"));
    }
}

export function Dashboard() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [activeValidations, setActiveValidations] = useState({});
    const [activeButton, setActiveButton] = useState('');
    const mdDown = useResponsive('down', 'md');
    const {data: oikosApiResponse, isLoading: isLoadingDashboard} = useOikosDashboard((error) => {
        handleError(error, t, toast, navigate);
    });
    const {data: validationReqApiResponse, isLoading: isValidationReqLoading} = useValidationReqDashboard();
    const {data: goalsHistory, isLoading: isHistoryLoading} = useGoalsHistory()


    useEffect(() => {
        if (!isValidationReqLoading && validationReqApiResponse) {
            const firstKeyWithData = Object.keys(validationReqApiResponse).find(key => validationReqApiResponse[key].length > 0);
            if (firstKeyWithData) {
                handleButtonClick(firstKeyWithData);
            }
        }
    }, [isValidationReqLoading, validationReqApiResponse]);

    const buttons = [{
        label: t('dashboard.more_highlights'),
        isActive: activeButton === "highlighted",
        onClick: () => handleButtonClick("highlighted")
    }, {
        label: t('dashboard.best_roi'),
        isActive: activeButton === "better_roi",
        onClick: () => handleButtonClick("better_roi")
    }, {
        label: t('dashboard.best_cashflow'),
        isActive: activeButton === "better_cashflow",
        onClick: () => handleButtonClick("better_cashflow")
    }, {
        label: t('dashboard.best_patrimony'),
        isActive: activeButton === "better_patrimony",
        onClick: () => handleButtonClick("better_patrimony")
    },];

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        const validationObjects = validationReqApiResponse[buttonName] || [];
        setActiveValidations(validationObjects);
        Mixpanel.track('Dashboard Button Clicked', {
            buttonName: buttonName
        });
    };


    return <AppPage containerComponent="box">

    {isLoadingDashboard ? null : <DashboardTour/>}
        <Stack spacing={1} className="tour_dashboard_description">
            <Typography variant="h6" align="left">{t('dashboard.investment')}</Typography>
            <Box>
                <Grid container spacing={mdDown ? 1 : 5}>
                    <Grid item xs={12} md={6} lg={4}>
                        <Box className="tour_dashboard_cards">
                            <OikosCard sectionKey="roi" title={t('financial.profitability.gross_roi')}
                                       data={oikosApiResponse}/>
                        </Box>

                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <OikosCard sectionKey="cash_flow" title={t('investment_goals.cash_flow.title')}
                                   data={oikosApiResponse}/>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <OikosCard sectionKey="patrimony" title="Patrimonio" data={oikosApiResponse}/>
                    </Grid>
                </Grid>
            </Box>

            <Box display="flex" justifyContent="flex-end">
                <Button variant="text" color="secondary"
                        onClick={() => navigate(ROUTES.properties.list)}>{t('dashboard.go_investments')}</Button>
            </Box>
            <Box className="tour_dashboard_chart">
                <DashboardChart data={goalsHistory} isLoading={isHistoryLoading}/>
            </Box>
        </Stack>
        <Grid container spacing={mdDown ? 2 : 5} mt={0}>

            <Grid item xs={12} md={9}>

                <Box
                    className="tour_dashboard_validations"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '16px',
                        alignSelf: 'stretch'
                    }}>
                    <BaseCard sx={{alignItems: 'inherit !important'}}>
                        <Typography variant="h6" align="left">{t('dashboard.my_validations')}</Typography>

                        <ScrollButtons buttons={buttons} indicator/>
                    </BaseCard>

                    {activeValidations.length > 0 ? (activeValidations.map(validation => (
                        <ValidationListItem key={validation?.ID} data={validation} mini={true}/>))) : (<Box sx={{
                            display: "flex", flexDirection: "column", alignItems: "center", width: "100%", pt: 2
                        }}>
                            <Typography variant="body2" sx={{mb: 2}}>
                                {t('dashboard.no_validation')}
                            </Typography><Button variant="outlined" color="secondary" size="small" sx={{mt: 2}}
                                                 onClick={() => navigate(ROUTES.validations.add)}>
                            {t('dashboard.add_validation')}
                        </Button>
                        </Box>

                    )}

                </Box>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                    <Button variant="text" color="secondary"
                            onClick={() => navigate(ROUTES.validations.list)}>{t('dashboard.go_validations')}</Button>
                </Box>
            </Grid>

            <Grid item xs={12} md={3}>
                <Box className="tour_dashboard_benefits">
                    <BenefitsCard/>
                </Box>

            </Grid>

        </Grid>
    </AppPage>;
}