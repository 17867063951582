import PageTitle from "../../components/titles";
import AppPage from "../../common/AppPage";
import {useTranslation} from 'react-i18next';
import {FiltersProvider} from "../../components/search_filter/context";
import {OptionsBar, useSettingsContext} from "../../components/settings";
import Box from "@mui/material/Box";
import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import {AddOikosButton, AddOikosFabButton} from "../oikos_add/";
import {useResponsive} from "../../hooks/use-responsive";
import OikosListItem from "./oikos-list-item";
import {EmptyOikosList} from "../../components/app-status";
import {selectorsFromSettings} from "../../utils/financial";
import {OikosSearchFilterBar} from "../../components/search_filter/";
import CustomFilterAwarePaginator from "../../components/paginator";
import {OikosItemFilterer} from "../../components/search_filter";
import {useOikosLister} from "../../services/oikos/oikos-lister";
import {LoadingScreenBar} from "../../components/loading-screen";
import {useNavigate} from "react-router-dom";
import {useProfileDetailer} from "../../services/profile-detailer";
import ROUTES from "../../routes";
import RefreshButton from "../../common/refresh-button";
import ProductUses from "../../common/product-uses/product-uses";
import {ListTour} from "../../components/tour";

function responseToData(response, settings) {
    if (!response?.length) return {};
    const selectors = selectorsFromSettings(settings);
    return response.map((i) => {
        const financial = i.financial[selectors.priceSelector]
        const cash_flow = financial.cash_flow[selectors.periodSelector]
        return {
            ...i,
            cash_flow_goal: i.cash_flow_goal[selectors.periodSelector],
            financial: {...financial, cash_flow: cash_flow[selectors.profileSelector]}
        }
    })
}

export default function OikosList() {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const smUp = useResponsive('up', 'sm');
    const [oikosList, setOikosList] = useState([]);
    const [pageItems, setPageItems] = useState([]);
    const settings = useSettingsContext();
    const {data: fetchedProfileData, isLoading: isLoadingProfile} = useProfileDetailer(0, (_) => {
    });
    const {data, isLoading: isLoadingList, refetch: refetchList} = useOikosLister();
    const lgDown = useResponsive('down', 'lg');

    useEffect(() => {
        setOikosList(responseToData(data, settings))
    }, [data, settings.useWaldenPrice, settings.useMonthlyPeriod, settings.useOptimistProfile]);

    const handleRefresh = () => {
        refetchList();
    };

    const renderComponent = () => {
        if (!isLoadingProfile && !fetchedProfileData?.investor_profile?.id) {
            navigate(ROUTES.onboarding.profile);
        }
        return <AppPage topBarTitle={t("oikos.title")} containerComponent="box">
            <ListTour tourKey="oikos_list" tourType="oikos"/>

            <Box sx={{pb: "50px"}}>
                {!lgDown && (
                    <Stack direction="row" justifyContent={lgDown ? "flex-end" : "space-between"} sx={{mb: '20px'}}>
                        <PageTitle>{t("oikos.title")}</PageTitle>
                        {smUp ? <Box className="tour-oikos-list-add">
                            <AddOikosButton fetchedProfileData={fetchedProfileData}/>
                        </Box> : <AddOikosFabButton fetchedProfileData={fetchedProfileData}/>}
                    </Stack>)}

                {lgDown && (<Stack direction="row" justifyContent="flex-end" sx={{mb: '20px'}}>
                    {smUp ? <Box className="tour-oikos-list-add">
                        <AddOikosButton fetchedProfileData={fetchedProfileData}/>
                    </Box> : <AddOikosFabButton fetchedProfileData={fetchedProfileData}/>}
                </Stack>)}
                <FiltersProvider>
                    <Box sx={{
                        height: "50px", display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1
                    }}>
                        <Box className="tour_list_options">
                            <OptionsBar/>
                        </Box>
                        <Box className="tour_list_search">
                            <OikosSearchFilterBar/>
                        </Box>

                    </Box>

                    {isLoadingList || isLoadingProfile ?
                        <LoadingScreenBar sx={{maxHeight: 400}}/> : oikosList.length ?
                            <>
                                <Box sx={{display: "flex", justifyContent: "space-between", mb: "5px", maxHeight: 35}}>
                                    <RefreshButton handleRefresh={handleRefresh}/>

                                    <ProductUses inOikos/>

                                </Box>
                                <Stack spacing={2} mt={2}>
                                    {pageItems.map((d) => <OikosListItem data={d} key={d.id}/>)}
                                </Stack>
                                <Box>
                                    <CustomFilterAwarePaginator items={oikosList} pageSize={10}
                                                                setPageItems={setPageItems}
                                                                doFilter={OikosItemFilterer}/>
                                </Box>
                            </> : <EmptyOikosList>
                                <Box className="tour-oikos-list-add">
                                    <AddOikosButton/>
                                </Box>
                            </EmptyOikosList>}
                </FiltersProvider>
            </Box>
        </AppPage>
    }

    return renderComponent();
}
